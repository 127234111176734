import { Box } from '@mui/material';
import Spinner from 'components/Spinner';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clientConfig } from 'utils/utils';

const HowItWorks = () => {
  const { t } = useTranslation();
  const [iframeHeight, setIframeHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {

    // Gestionnaire pour recevoir les messages de l'iframe
    const handleMessage = (event: MessageEvent) => {
      try {
        // Vérifier l'origine pour la sécurité
        if (event.origin === clientConfig.mainLandingPageWp) {
          // Vérifier si event.data est une chaîne
          if (typeof event.data === 'string') {
            setLoading(true);
            // Parser la chaîne JSON
            const parsedData = JSON.parse(event.data);
            console.log('parsedData', parsedData);
            // Vérifier si le message contient docHeight
            if (parsedData && typeof parsedData.docHeight === 'number') {
              setIframeHeight(parsedData.docHeight + 50);
              setIsVisible(true);
            }
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Erreur lors du traitement du message:', error);
        setLoading(false);
      }
    };

    // Ajouter l'écouteur d'événement pour les messages
    window.addEventListener('message', handleMessage);

    // Nettoyer l'écouteur lors du démontage du composant
    return () => {
      window.removeEventListener('message', handleMessage);

    };

  }, []);
  
console.log('loading', loading);
console.log('isVisible', isVisible);

  return (
    <Box  
    ref={containerRef}
    sx={{ display:"block"}}
    >
      {loading && !isVisible ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Spinner />
        </Box>
      ) : (
        <iframe 
          src={clientConfig.howItWorksWp}
          title="How It Works"
          style={{ 
            width: '100%',
            height: `${iframeHeight}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            overflow: 'hidden'
          }}
          onLoad={() => {
            setLoading(false);
          }}
        />
      )}
    </Box>
  );
};

export default HowItWorks;
